import { TableContainer, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";

const applyClassByFlag = (params: any) => {
    if(params === "4") {
        return "bg-lightGray"
    }
    
    if (params === "3") {
        return "bg-red";
    }

    if (params === "2") {
        return "bg-yellow";
    }

    if (params === "1") {
        return "bg-green";
    }

    return "" as string;
};

export const DiamServiceSpecificCriteria = (item: any) => {
    let shouldAddRow = (rowName: string) => {
        return !item.data.rowData.every((row:any) => row[rowName] === null);
    }
    return (
        <TableWrapper>
            <TableContainer sx={{marginTop: "25px"}}>
                <StyledTable sx={{mb:4}}>
                    <TableHead sx={{backgroundColor: "#1e22aa", padding: "7px"}}>
                        <TableRow>
                            {item.data.headerData.map((row: any, index:number) => (
                                <TableCell align="center" sx={{color: "#ffffff", fontWeight: 700}} key={`diamServiceHeader-${index}`}>
                                    {row.col1}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.data.rowData.map((row: any, index:number) => (
                            <TableRow
                                key={index}
                                sx={row.stage === null ? { backgroundColor: "#55c1e9"}: {}}
                            >
                                <TableCell align="center">{row.stage}</TableCell>
                                <TableCell align="center">
                                    {row.stage === null ? '' : row.col2 === true ? "Yes" : "No"}
                                </TableCell>
                                {row.col1 !== null &&
                                <TableCell align="left" sx={row.stage === null ? { color: "#fff", fontWeight: 700}: {}}>
                                    {row.stage === null ? `Focus Area: ${row.col1}`:row.col1}
                                </TableCell>                            
                                }
                                {shouldAddRow("col3") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col3)}></TableCell>                            
                                }
                                {shouldAddRow("col4") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col4)}></TableCell>                            
                                }
                                {shouldAddRow("col5") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col5)}></TableCell>                            
                                }
                                {shouldAddRow("col6") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col6)}></TableCell>                            
                                }
                                {shouldAddRow("col7") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col7)}></TableCell>                            
                                }
                                {shouldAddRow("col8") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col8)}></TableCell>                            
                                }
                                {shouldAddRow("col9") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col9)}></TableCell>                            
                                }
                                {shouldAddRow("col10") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col10)}></TableCell>                            
                                }
                                {shouldAddRow("col11") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col11)}></TableCell>                            
                                }
                                {shouldAddRow("col12") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col12)}></TableCell>                            
                                }
                                {shouldAddRow("col13") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col13)}></TableCell>                            
                                }
                                {shouldAddRow("col14") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col14)}></TableCell>                            
                                }
                                {shouldAddRow("col15") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col15)}></TableCell>                            
                                }
                                {shouldAddRow("col16") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col16)}></TableCell>                            
                                }
                                {shouldAddRow("col17") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col17)}></TableCell>                            
                                }
                                {shouldAddRow("col18") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col18)}></TableCell>                            
                                }
                                {shouldAddRow("col19") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col19)}></TableCell>                            
                                }
                                {shouldAddRow("col20") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col20)}></TableCell>                            
                                }
                                {shouldAddRow("col21") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col21)}></TableCell>                            
                                }
                                {shouldAddRow("col22") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col22)}></TableCell>                            
                                }
                                {shouldAddRow("col23") &&
                                <TableCell align="center" sx={{minWidth:"125px"}} className={applyClassByFlag(row.col23)}></TableCell>                            
                                }

                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};