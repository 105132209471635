import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { ApiBaseRoute } from "../../api_helper";
import { AccountDetailModel, AccountMassUpdateModel, AccountSearchRequest, OrganizationMerger } from "./Account.models";

export const GetAccountListColumns = ():GridColDef[] => {
    return [
        // { field: "id", headerName: "Validation ID", flex: .4, filterable: false },
        { field: "accountType", headerName: "Facility Type", flex: .6, filterable: false },
        { field: "name", headerName: "Name", flex: 1, filterable: false},
        { field: "street", headerName: "Address", flex: 1, filterable: false},
        { field: "uniqueID", headerName: "HIMSS ID", flex: .5, filterable: false},
        { field: "state", headerName: "State", flex: .6, filterable: false},
        { field: "country", headerName: "Country", flex: .6, filterable: false},

        {
            field: "lastCompletedSurveyDate",
            headerName: "Last Survey Date",
            flex: .6,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        },
        {
            field: "dateModified",
            headerName: "Modified Date",
            flex: .6,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: ({value}) => value? moment(value).format("MM/DD/YYYY") : "",
            renderCell: (params) => {
                return params.value? moment(params.value).format("MM/DD/YYYY") : "";
            }
        }
    ]
};

export const SearchAccounts = (request: AccountSearchRequest, token: string|undefined):Promise<Response> => {
    let requestCopy = {
        ...request, 
        facilityIDs: request.facilityIDs.map(x => x.value),
        assignedToUserIDs: request.assignedToUserIDs.map(x => x.value),
        countryIDs: request.countryIDs.map(x => x.value),
        stateIDs: request.stateIDs.map(x => x.value),
        facilityTypeIDs: request.facilityTypeIDs.map(x => x.value),
        parentAccountUniqueIDs: request.parentAccountUniqueIDs.map(x => x.value)
    };
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Accounts/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(requestCopy)
    });
};

export const GetAccountDetail = (accountUniqueID: number, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Accounts/Detail?id=" + accountUniqueID, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const SaveAccountDetail = (accountDetailModel: AccountDetailModel, token: string|undefined):Promise<Response> => {
    return fetch(ApiBaseRoute + "Accounts/Save", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(accountDetailModel)
    });
};

export const DeleteAccount = (uniqueID: number, token: string|undefined):Promise<Response> => {
    return fetch(`${ApiBaseRoute}Accounts/Detail?uniqueID=${uniqueID}`, {
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};

export const GetReportURL = (reportModel: string, uniqueID: number):string => {
    let route = "";

    switch(reportModel){
        case "Digital Health Indicator":
            route = `/aar/8/${uniqueID}`;
            break;
        case "EMRAM 2022":
            route = `/aar/12/${uniqueID}`;
            break;
        case "CISOM":
            route = `${process.env.REACT_APP_REPORT_BASE_URL}CISOM-GAP-ANALYSIS/${uniqueID}`;
            break;
        case "DIAM":
            route = `/aar/6/${uniqueID}`;
            break;
        case "C-COMM":
            route = `/aar/14/${uniqueID}`;
            break;
        case "INFRAM 2024":
            route = `/aar/15/${uniqueID}`;
            break;
        case "AMAM 2024":
            route = `/aar/16/${uniqueID}`;
            break;
    }

    return route;
};

export const MergeAccounts = (model: OrganizationMerger, token: string|undefined): Promise<Response> => {
    return fetch(`${ApiBaseRoute}Accounts/Merge`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify({...model, toUniqueID: model.toUniqueID!.value})
    });
};

export const MassUpdateAccounts = (model: AccountMassUpdateModel, token?: string): Promise<Response> => {
    return fetch(`${ApiBaseRoute}Accounts/MassUpdate`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        },
        body: JSON.stringify(model)
    });
}