import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";

export const DiamColorLegend = (item: any) => {
    
    return (
        <TableWrapper>
            <TableContainer sx={{marginTop: "25px", pageBreakInside:"avoid"}} >
                <StyledTable>
                    <TableHead sx={{backgroundColor: "#1e22aa", padding: "7px"}}>
                        <TableRow>
                            <TableCell align="center" sx={{color: "#ffffff", fontWeight: 700}}>
                                Rating
                            </TableCell>
                            <TableCell align="center" sx={{color: "#ffffff", fontWeight: 700}}>
                                Legend
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{backgroundColor: "#3cd5af"}}></TableCell>
                            <TableCell >No Gap - your instituition fully meets the requirement, no action needed</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{backgroundColor: "#ffc72c"}}></TableCell>
                            <TableCell >Partial Gap - your institution has done first steps towards meeting the requirement but there is space for improvement</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{backgroundColor: "#ff5a5a"}}></TableCell>
                            <TableCell >Gap - your instituition does not meet the requirement, improvements might be considered</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{backgroundColor: "#d3d3d3"}}></TableCell>
                            <TableCell>Not Applicable - the line item is not relevant for a specific service area and/or based on assessment logic</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{borderRight: "solid .5px #d3d3d3"}}></TableCell>
                            <TableCell>No Response - your institution did not provide information for that requirement</TableCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </TableWrapper>
    )
};
