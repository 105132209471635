import { Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { forEach } from "lodash";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AchievementListSearchRequest, CreateDefaultAchievementListSearchRequest } from "./AchievementList.models";
import { GetAchievementListColumns, SearchAchievementList } from "./AchievementList.service";
import NumericDropDownModel from "../../../../models/NumericDropDown.model";
import BaseLayout from "../../../../layout/BaseLayout.component";
import RightMenu from "../../../../layout/RightMenu.component";
import TypeAheadMulti from "../../../../components/formControls/TypeAheadMultiBase.component";
import { GetMaturityModelSearch } from "../../../../services/MaturityModel.service";
import { GetCountryTypeAheadSearch } from "../../../../services/Country.service";
import { GetGeographicRegionTypeAheadSearch } from "../../../../services/GeographicRegion.service";
import AchievementListMap from "./AchievementListMap.component";
import NoFilterToolBar from "../../../../components/dataGrid/NoFilterToolBar.component";
import { GetRegionTypeAheadSearch } from "../../../../services/Region.service";

interface AchievementListProps {
    hideHeader?: boolean,
    hideNavigation?: boolean
}
 
function AchievementList(props: AchievementListProps){
    const columns: GridColDef[] = useMemo(() => GetAchievementListColumns(), []);
    const location = useLocation();
    const [queryOptions, setQueryOptions] = React.useState<AchievementListSearchRequest>(CreateDefaultAchievementListSearchRequest(location));
    const [rowData, setRowData] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [mapData, setMapData] = React.useState([]);
    const {hideHeader, hideNavigation} = props;

    React.useEffect(() => {    
            setIsLoading(true);
            SearchAchievementList(queryOptions)
            .then((response) => response.json())
            .then((data) => {
                setRowData(data.gridData);
                setMapData(data.mapData);
                if(data && data.gridData.length > 0){
                    setRowCount(data.gridData[0].totalCount);
                } else {
                    setRowCount(0);
                }
            }).finally(() => setIsLoading(false));

    }, [queryOptions]); //only run useEffect if the queryOptions change

    const onPageChange = React.useCallback((newPage: number) => {
        setQueryOptions((prevState) => { return {...prevState, page: newPage} });
    }, []);

    const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions((prevState) => { return {...prevState, sortColumn: sortParam.field, sortDirection: sortParam.sort} });
        });
    }, []);  

    const changeCountryQueryOptions = React.useCallback((newCountries: NumericDropDownModel[]) => {
        setQueryOptions((prevState)=>{ return {...prevState, countries: newCountries, geographicRegions: [], page: 0} });
    }, []);

    const changeRegionQueryOptions = React.useCallback((newRegions: NumericDropDownModel[]) => {
        setQueryOptions((prevState)=>{ return {...prevState, geographicRegions: newRegions, page: 0} });
    }, []);
 
    const resetQueryOptions = React.useCallback(() => {
        setQueryOptions((prevState)=>{ return {...prevState, geographicRegions: [], countries: [], regions:[], page: 0} });
    }, []); 

    const childMenuContent = (
        <RightMenu>
                <List component="nav">
                    <ListItem>
                        <Typography variant="h6">Action Items</Typography>
                    </ListItem>
                    <ListItemButton onClick={() => setQueryOptions(CreateDefaultAchievementListSearchRequest(location))} key="reset">
                        Reset Filters
                    </ListItemButton>
                    <Divider/>
                    <ListItem>
                        <Typography variant="h6">Filters</Typography>
                    </ListItem>
                    <ListItem>
                        <TypeAheadMulti
                            value={queryOptions.maturityModel}
                            onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, maturityModel: newValue, page: 0} })}
                            label="Maturity Model"
                            searchCallback={(searchTerm?:string) => GetMaturityModelSearch(searchTerm, [7,8,9,10,11,12,13,15,16])}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <InputLabel variant="standard">Stages</InputLabel>
                            <Select
                                value={queryOptions.stages}
                                multiple
                                onChange={(event: SelectChangeEvent<string[]>, child: React.ReactNode) => setQueryOptions((prevState) => { return {...prevState, stages: event.target.value as string[], page: 0} })}
                                label="Stages"
                                fullWidth
                                variant="standard"                               
                            >
                                <MenuItem value="6">Stage 6</MenuItem>
                                <MenuItem value="7">Stage 7</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                    <TypeAheadMulti
                        value={queryOptions.regions}
                        disabled={isLoading}
                        label="Regions"
                        onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, regions: newValue} })}
                        searchCallback={(searchTerm?:string) => GetRegionTypeAheadSearch(searchTerm, [2,4,5,7,1001,1004])}
                    />
                    </ListItem>
                    <ListItem>
                        <TypeAheadMulti 
                            label="Search Countries"
                            value={queryOptions.countries}
                            onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => {
                                setQueryOptions((prevState) => { return {...prevState, countries: newValue, geographicRegions: [], page: 0} })}
                            }
                            searchCallback={(searchTerm?: string) => GetCountryTypeAheadSearch('', searchTerm, queryOptions.regions)}
                        />
                    </ListItem>
                    <ListItem>
                        <TypeAheadMulti 
                            label="Search States"
                            value={queryOptions.geographicRegions}
                            onChange={(event: React.SyntheticEvent<Element>, newValue: NumericDropDownModel[]) => setQueryOptions((prevState) => { return {...prevState, geographicRegions: newValue, page: 0} })}
                            searchCallback={(searchTerm?: string) => GetGeographicRegionTypeAheadSearch('', searchTerm, queryOptions.countries?.map(c => c.value))}
                            disabled={queryOptions.countries.length === 0}
                        />
                    </ListItem>
                </List>
            </RightMenu>
    );

    return (
        <BaseLayout childMenu={childMenuContent} hideHeader={hideHeader} hideNavigation={hideNavigation}>
            <AchievementListMap 
                mapData={mapData} 
                queryOptions={queryOptions} 
                changeCountryQueryOptions={changeCountryQueryOptions}
                changeRegionQueryOptions={changeRegionQueryOptions}
                resetQueryOptions={resetQueryOptions}
                isLoading={isLoading}
            />

            <Grid sx={{display: "flex", height: "100%", ...(hideHeader && hideNavigation ? {maxHeight: "665px"} : {})}}>
                <DataGrid
                    columns={columns}
                    rows={rowData}
                    filterMode="server"
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode="server"
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'Insights',
                            gridConfigName: 'insightGridState'
                        }
                    }}
                />
            </Grid>
        </BaseLayout>
    );
}

export default AchievementList;