import { Typography } from "@mui/material";
import { preStyledHeadings } from "./PreStying.component";



export const HeadingWithPreStyling = (item:any) => {
    let styleOverride = item.data.text[0].style
    ? JSON.parse(item.data.text[0].style)
    : [];
    let outputText = "";

    if (item.data.text.length > 1) {
        item.data.text.map((textPiece: any, index: number) => {
            return outputText += textPiece.value;
        });
    } else {
        outputText = item.data.text[0].value;
    }

    return (
        <Typography
            variant="h1"
            sx={{
                fontSize: "1.3em",
                marginBottom: "8px",
                marginTop: "16px",
                color: "#1e22aa",
                fontWeight: "700",
                ...(preStyledHeadings[item.type] && {
                    ...preStyledHeadings[item.type],
                }),
                ...(styleOverride && {
                    ...styleOverride,
                }),
                
            }}
        >
            {outputText}
        </Typography>
    );
}

export const Heading1 = (item: any) => {
    let styleOverride = item.data?.text[0]?.style
        ? JSON.parse(item.data.text[0].style)
        : [];
    let outputText = "";

    if (item.data.text.length > 1) {
        item.data.text.map((textPiece: any, index: number) => {
            return outputText += textPiece.value;
        });
    } else {
        outputText = item.data.text[0].value;
    }

    return (
        <Typography
            variant="h1"
            sx={{
                fontSize: "1.3em",
                marginBottom: "8px",
                marginTop: "16px",
                color: "#1e22aa",
                fontWeight: "700",
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {outputText}
        </Typography>
    );
};

export const BasicText = (item: any) => {
    if(item?.data?.text[0] === undefined){
        debugger;
    }
    let styleOverride = item.data.text[0].style
        ? JSON.parse(item.data.text[0].style)
        : [];
    return (
        <Typography
            sx={{
                fontSize: "1em",
                marginBottom: "20px",
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {item.data.text[0].value}
        </Typography>
    );
};
