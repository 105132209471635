import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";

const renderCell = (row: any, rowName: string) => {
    if (row.id !== 10 && !isNaN(row[rowName])) {
        return row[rowName].toLocaleString() + "%";
    }

    return row[rowName];
}
    
const applyDynamicClass = (row: any) => {
    if (row.id > 8) {
        return "bg-blue";
    }

    if (row.hgFailed === 1) {
        return "bg-red";
    }

    if (row.col2 > 69.5) {
        return "bg-green";
    }

    if (isNaN(row.col2)) {
        return "" as string;
    }

    return "bg-yellow" as string;
}

export const DiamOverallScore = (item: any) => (
    <TableWrapper>
        <TableContainer>
            <StyledTable sx={{minWidth: "600px"}}>
                <TableHead>
                </TableHead>
                <TableBody>
                    {item.data.rowData.map((row: any, index:number) => (
                        <TableRow
                            key={`diamOveralllScoreRow-${index}`}
                        >
                            <TableCell align="center">{row.col1}</TableCell>
                            <TableCell align="center" className={applyDynamicClass(row)}>{renderCell(row, "col2")}</TableCell>
                            <TableCell align="center">{row.col3}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    </TableWrapper>
);
